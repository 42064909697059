@import "bootstrap/dist/css/bootstrap-reboot.min.css"
@import "bootstrap/dist/css/bootstrap.min.css"

@import "variables"

.btn.btn-info
  background-color: var(--main-color)
  border-color: var(--secondary-color)

.bg-info
  background-color: var(--main-color) !important